<template>
  <div v-bind="this.$attrs">
    <n-button-regular
      class="sm:px-4 px-2 sm:w-auto w-full"
      @click.native="onButtonClick('report.theft')"
      secondary
    >
      <n-html :text="$t('layout.common.button.report_theft')" />
    </n-button-regular>
    <n-button-regular
      class="sm:ml-4 sm:px-4 px-2 sm:w-auto w-full"
      @click.native="onButtonClick('report.damage')"
    >
      <n-html :text="$t('layout.common.button.report_damage')" />
    </n-button-regular>
  </div>
</template>

<script>
export default {
  name: "components.button.damage-theft-buttons",
  props: {
    extraButtonAction: Function
  },
  methods: {
    onButtonClick(route) {
      this.$router.push({ name: route }).catch(() => {});
      if (this.extraButtonAction) {
        this.extraButtonAction();
      }
    }
  }
};
</script>
