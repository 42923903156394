<template>
  <Accordion class="insurance-card p-0" no-padding>
    <accordion-header
      :collapsed="internal_collapsed"
      @click.native="internal_collapsed = !internal_collapsed"
    >
      <div class="flex flex-col lg:flex-row font-serif">
        <h4
          :class="['text-brand-purple-500 text-lg font-medium leading-6 mr-5']"
        >
          {{ claim.claim.navision_ext_id }}
        </h4>
        <div class="text-brand-gray-500">
          {{ claim.claim.contract.contract_description }}
        </div>
      </div>
    </accordion-header>
    <accordion-body
      :collapsed="internal_collapsed"
      class="flex flex-col lg:flex-row"
    >
      <div class="w-full" @click="onRowClick(claim)">
        <div class="border-b lg:border-t flex flex-col gap-4 py-4">
          <insurance-detail-item
            :title="$t('claims.table.type')"
            :value="claim.claim_type"
          />
          <insurance-detail-item
            @click="onRowClick(claim)"
            :title="$t('claims.table.date')"
            :value="format_date(claim.claim_date)"
          />
          <insurance-detail-item
            @click="onRowClick(claim)"
            :title="$t('claims.table.status')"
            :value="claim.claim_status"
          />
          <!--          <insurance-detail-item-->
          <!--            @click="onRowClick(claim)"-->
          <!--            :title="$t('claims.table.repair_status')"-->
          <!--            :value="claim.claim_repair_status"-->
          <!--          />-->
          <!--          <insurance-detail-item-->
          <!--            @click="onRowClick(claim)"-->
          <!--            :title="$t('claims.table.repair_status_last_updated')"-->
          <!--            :value="-->
          <!--              claim.claim.repair_status_last_updated === '0001-01-01T00:00:00Z'-->
          <!--                ? '-'-->
          <!--                : this.format_date_time(claim.claim.repair_status_last_updated)-->
          <!--            "-->
          <!--          />-->
        </div>
      </div>
    </accordion-body>
  </Accordion>
</template>

<script>
import format from "../../mixins/format";
import AccordionHeader from "@/components/theme/accordion/accordion-header";
import Accordion from "@/components/theme/accordion/accordion";
import AccordionBody from "@/components/theme/accordion/accordion-body";
import InsuranceDetailItem from "@/components/insurances/details/insurance-detail-item";
import moment from "moment";

export default {
  name: "components.claims.card-claim-mobile-overview",
  components: {
    InsuranceDetailItem,
    AccordionBody,
    Accordion,
    AccordionHeader
  },
  mixin: [format],
  data: () => ({
    internal_collapsed: true,
    collapsed: false
  }),
  props: {
    claim: Object,
    active: Boolean,
    rowClick: Function
  },
  methods: {
    onRowClick(row) {
      if (this.rowClick) {
        this.rowClick(row);
      }
    },
    format_date: value => {
      let formattedDate = moment(value).format("DD-MM-YYYY");
      return formattedDate !== "01-01-0001" ? formattedDate : "";
    },
    format_date_time: value => {
      let formattedDateTime = moment(value).format("DD-MM-YYYY HH:MM:SS");
      return formattedDateTime !== "01-01-0001" ? formattedDateTime : "";
    }
  },
  mounted() {
    this.internal_collapsed = this.collapsed;
  }
};
</script>

<style>
.insurance-card .card-body {
  padding: 0;
}

.insurance-card .card-body .insurance-card-title {
  cursor: pointer;
}

.insurance-card .card-body .insurance-card-title .insurance-card-title-icon {
  right: 20px;
  bottom: 25px;
  position: absolute;
}

.insurance-card-footer-left div {
  float: left;
}

.mobile .insurance-card-footer-left {
  display: grid;
  margin-bottom: 10px;
}

.insurance-card-footer-right {
  float: right;
  position: relative;
}

.insurance-card-footer-right a {
  margin-left: 15px;
}

.mobile .insurance-card-footer-right {
  float: left;
}
</style>
