<template>
  <div
    class="padding-top-grid-between padding-left-grid-between padding-right-grid-between padding-bottom-grid-between"
  >
    <div class="grid grid-cols-12 p-2 md:p-8">
      <div class="col-span-12 sm:col-span-8 m-4 mt-6">
        <h1
          class="uppercase text-brand-purple-500 font-serif font-bold text-3xl"
        >
          <n-html :text="$t('claims.title')" />
        </h1>
      </div>
      <div class="col-span-12 sm:col-span-4 m-4 mt-6 sm:hidden">
        <DamageTheftButtons class="flex flex-row justify-between gap-2" />
      </div>
      <div class="col-span-12">
        <div v-if="!this.isMobile(w.width)" class="desktop mx-4 mt-6">
          <n-table
            v-if="claims.length"
            :columns="columns"
            :rows="rows"
            :row-click="showClickedClaimModal"
          />
        </div>
        <div v-else class="mobile">
          <div v-if="claims.length">
            <CardClaimMobileOverview
              v-for="claim in rows"
              v-bind:key="claim.claim.id"
              :claim="claim"
              :active="true"
              :row-click="showClickedClaimModal"
            />
          </div>
        </div>
        <n-alert variant="primary" v-if="claims.length == 0">
          <div class="flex flex-row font-semibold">
            <n-icon name="Info" class="mr-2"></n-icon>
            <n-html :text="$t('claims.no_items_title')" />
          </div>
          <div class="text-brand-purple-500 mt-2">
            <n-html :text="$t('claims.no_items')" />
          </div>
        </n-alert>
      </div>
      <div class="col-span-12 my-8">
        <damage-theft-buttons
          class="col-span-12 flex flex-row justify-between gap-x-2 hidden lg:block"
        />
      </div>
      <modal-claim-details
        :show="claim_detail_visible"
        :claim="selected_claim"
        :onCloseModal="closeOpenClaimModal"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ModalClaimDetails from "./../../components/claims/modal-claim-details";
import CardClaimMobileOverview from "./../../components/claims/card-claim-mobile-overview";
import DamageTheftButtons from "./../../components/theme/button/damage-theft-buttons";
import Layout from "./../../mixins/layout";
import format from "@/mixins/format";
import moment from "moment";

export default {
  name: "pages.insurances.index",
  components: {
    ModalClaimDetails,
    DamageTheftButtons,
    CardClaimMobileOverview
  },
  mixins: [Layout, format],
  computed: {
    ...mapGetters({
      claims: "claims/getAll"
    }),
    rows() {
      return this.claims
        .map(c => {
          const { contract } = c;
          return {
            product: contract.multidevice
              ? c.product_description
              : contract.description,
            claim_type: this.getClaimType(c),
            claim_status:
              c.status !== "" && c.status !== undefined
                ? this.$t(
                    `claims.status.${c.status.replace(" ", "_").toLowerCase()}`
                  )
                : "-",
            claim_date: c.created_at,
            claim: c,
            claim_number: c.navision_ext_id || "-"
            // claim_repair_status: c.repair_status || "-"
          };
        })
        .sort(
          (a, b) => new Date(b.claim.created_at) - new Date(a.claim.created_at)
        );
    }
  },
  data() {
    return {
      w: {
        width: 0,
        height: 0
      },
      columns: [
        {
          title: this.$t("claims.table.claim"),
          key: "claim_number",
          sortable: true,
          col_width: "min-w-36 w-1/6"
        },
        {
          title: this.$t("claims.table.product"),
          key: "product",
          sortable: true,
          col_width: "min-w-36 w-1/6"
        },
        {
          title: this.$t("claims.table.type"),
          key: "claim_type",
          sortable: true,
          col_width: "min-w-36 w-1/6"
        },
        {
          title: this.$t("claims.table.date"),
          key: "claim_date",
          sortable: true,
          formatValue: function(value) {
            let formattedDate = moment(value).format("DD-MM-YYYY");
            return formattedDate !== "01-01-0001" ? formattedDate : "";
          },
          col_width: "min-w-36 w-1/6"
        },
        {
          title: this.$t("claims.table.status"),
          key: "claim_status",
          sortable: true,
          col_width: "min-w-36 w-1/6"
        }
        // {
        //   title: this.$t("claims.table.repair_status"),
        //   key: "claim_repair_status",
        //   sortable: true,
        //   col_width: "min-w-36 w-1/6"
        // }
      ],
      claim_detail_visible: false,
      claim_theft_report_visible: false,
      selected_claim: null
    };
  },
  mounted() {
    this.$store.dispatch("claims/getAll");
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.w.width = window.innerWidth;
      this.w.height = window.innerHeight;
    },
    getClaimType(claim) {
      if (claim.damage_cause === "VALSCHADE") {
        return this.$t(`report.damage.type.type_drop`);
      } else if (claim.damage_cause === "VOCHTSCHADE") {
        return this.$t(`report.damage.type.type_water`);
      } else if (claim.damage_cause === "TECHNISCH DEFECT") {
        return this.$t(`report.damage.type.type_technical`);
      } else if (claim.damage_cause === "BEROVING") {
        return this.$t(`report.theft.type.type_robbery`);
      } else if (claim.damage_cause === "VERMISSING") {
        return this.$t(`report.theft.type.type_missing`);
      } else if (claim.damage_cause === "INBRAAK") {
        return this.$t(`report.theft.type.type_break_in`);
      }
      return "";
    },
    showClickedClaimModal(row) {
      this.claim_detail_visible = !this.claim_detail_visible;

      if (this.claim_detail_visible) {
        this.selected_claim = row.claim;
      } else {
        this.selected_claim = null;
      }
    },
    closeOpenClaimModal() {
      this.claim_detail_visible = false;
      this.selected_claim = null;

      this.$store.dispatch("claims/getAll");
    },
    showTheftReportModal() {
      this.claim_theft_report_visible = !this.claim_theft_report_visible;

      if (this.claim_theft_report_visible) {
        this.selected_claim = this.claims[0];
      } else {
        this.selected_claim = null;
      }
    },
    closeTheftReportModal() {
      this.claim_theft_report_visible = false;
      this.selected_claim = null;
    },
    visible() {
      return !this.isMobile(window.innerWidth);
    }
  }
};
</script>
