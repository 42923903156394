<template>
  <modal
    :class="show ? '' : 'hidden'"
    @close="onCloseModal"
    v-if="claim != null"
    :title="$t('claims.detail.title')"
    :no-footer="!mustUpdateClaim"
  >
    <p class="font-serif py-6">
      <n-html :text="$t('claims.detail.sub_title')" />
    </p>
    <div class="border-b pb-4">
      <p class="font-serif font-semibold pb-1">
        <n-html :text="$t('claims.detail.claim_number')" />
      </p>
      <p class="font-serif">
        {{ claim.navision_ext_id || "-" }}
      </p>
    </div>
    <div class="border-b py-4">
      <p class="font-serif font-semibold pb-1">
        <n-html :text="$t('claims.detail.status')" />
      </p>
      <p
        class="bg-brand-purple-100 w-40 h-9.5 leading-9 px-4 rounded-full font-serif font-semibold text-brand-purple-500 text-center"
      >
        {{
          claim.status !== ""
            ? this.$t(
                `claims.status.${claim.status.replace(" ", "_").toLowerCase()}`
              )
            : "-"
        }}
      </p>
    </div>
    <div v-if="!isTheftClaim">
      <div class="border-b py-4">
        <p class="font-serif font-semibold pb-1">
          <n-html :text="$t('claims.detail.repair_status')" />
        </p>
        <p class="font-serif">
          {{
            claim.repair_status !== ""
              ? claim.repair_status
              : claim.on_hold_reason_code_description || "-"
          }}
        </p>
      </div>
      <!--      <div class="border-b py-4">-->
      <!--        <p class="font-serif font-semibold pb-1">-->
      <!--          <n-html :text="$t('claims.detail.repair_status_last_updated')" />-->
      <!--        </p>-->
      <!--        <p class="font-serif">-->
      <!--          {{-->
      <!--            claim.repair_status_last_updated === "0001-01-01T00:00:00Z"-->
      <!--              ? "-"-->
      <!--              : this.format_date(-->
      <!--                  claim.repair_status_last_updated,-->
      <!--                  "DD-MM-YYYY HH:MM:SS"-->
      <!--                )-->
      <!--          }}-->
      <!--        </p>-->
      <!--      </div>-->
      <div v-if="isOnSiteRouting">
        <div class="border-b py-4">
          <p class="font-serif font-semibold pb-1">
            <n-html :text="$t('claims.detail.shop_name')" />
          </p>
          <p class="font-serif">
            {{ claim.shop_name || "-" }}
          </p>
        </div>
        <div class="border-b py-4">
          <p class="font-serif font-semibold pb-1">
            <n-html :text="$t('claims.detail.shop_phone_no')" />
          </p>
          <p class="font-serif">
            {{ claim.shop_phone_no || "-" }}
          </p>
        </div>
      </div>
    </div>
    <div class="border-b py-4">
      <p class="font-serif font-semibold pb-1">
        <n-html :text="$t('claims.detail.product')" />
      </p>
      <div class="font-serif">
        <span>{{
          claim.contract.multidevice
            ? claim.contract.contract_description
            : claim.contract.description
        }}</span>
        <div v-if="claim.product_description">
          <p class="font-serif">
            {{ claim.product_description }}
          </p>
        </div>
      </div>
    </div>
    <div class="border-b py-4">
      <p class="font-serif font-semibold pb-1">
        <n-html :text="$t('claims.detail.claim_type')" />
      </p>
      <p class="font-serif">
        {{ getClaimType() }}
      </p>
    </div>
    <div class="border-b py-4">
      <p class="font-serif font-semibold pb-1">
        <n-html :text="$t('claims.detail.claim_date')" />
      </p>
      <p class="font-serif">
        {{ this.format_date(claim.created_at, "DD-MM-YYYY") }}
      </p>
    </div>
    <div class="border-b py-4">
      <p class="font-serif font-semibold pb-1">
        <n-html :text="$t('claims.detail.claim_area')" />
      </p>
      <p class="font-serif">
        {{ claim.damage_description }}
      </p>
    </div>
    <div v-if="!isTheftClaim && this.claim.shipping_label !== ''">
      <div class="border-b py-4">
        <p class="font-serif font-semibold pb-1">
          <n-html :text="$t('claims.detail.shipping.label')" />
        </p>
        <n-button-link @click.native="downloadShippingLabel()">
          <n-html :text="$t('claims.detail.shipping.download')" />
        </n-button-link>
      </div>
    </div>
    <div v-if="isTheftClaim">
      <div class="border-b py-4">
        <p class="font-serif font-semibold pb-1">
          <n-html :text="$t('claims.detail.report_no.label')" />
        </p>
        <p
          class="font-serif"
          v-if="!mustUpdateClaim || claim.official_report_no"
        >
          {{ claim.official_report_no || "-" }}
        </p>
        <n-form-group
          v-else
          id="input-group-report_no"
          :invalid-feedback="$t('claims.detail.report_no.feedback.invalid')"
          :state="form_states.report_no"
        >
          <n-form-input-default
            id="input-report_no"
            :placeholder="$t('claims.detail.report_no.placeholder')"
            v-model="data.report_no"
            type="text"
          />
        </n-form-group>
      </div>
      <div class="border-b py-4" v-if="!mustUpdateClaim">
        <p class="font-serif font-semibold pb-1">
          <n-html :text="$t('claims.detail.report_date.label')" />
        </p>
        <p class="font-serif">
          {{
            claim.official_report_date !== "0001-01-01"
              ? this.format_date(claim.official_report_date, "DD-MM-YYYY")
              : "-"
          }}
        </p>
      </div>
      <div class="border-b py-4" v-if="mustUpdateClaim">
        <p class="font-serif font-semibold pb-1">
          <n-html :text="$t('claims.detail.files.label')" />
        </p>
        <div
          class="relative border border-dashed border-brand-purple-500 bg-brand-purple-100/25 cursor-pointer"
        >
          <p
            class="hidden lg:block absolute pl-6 top-1/2 -translate-y-1/2 left-0 right-1/3 text-left align-middle text-brand-purple-500"
          >
            <n-html :text="$t('claims.detail.files.placeholder')" />
          </p>
          <n-button-regular
            @click.native="close"
            class="absolute left-0 lg:left-auto lg:right-0 top-1/2 -translate-y-1/2 mx-6 text-brand-purple-500"
          >
            <n-html :text="$t('claims.detail.files.button')" />
          </n-button-regular>

          <input
            type="file"
            class="opacity-0 h-20 w-full cursor-pointer"
            multiple
            @change="onChange"
            accept="image/*"
          />
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-y-2 gap-x-6 py-2">
          <div
            class="flex justify-between bg-brand-gray-100 text-xs text-brand-gray-400 px-3 py-1"
            v-for="file in files"
            :key="file.name"
          >
            {{ file.name }}
            <n-icon
              xs
              name="Delete"
              class="cursor-pointer"
              @click.native="removeFile(index)"
            />
          </div>
        </div>
      </div>
    </div>
    {{ /* Footer */ }}
    <template v-slot:footer>
      <div class="ml-auto">
        <n-button-regular @click.native="updateTheftReport" :disabled="!valid">
          <n-html :text="$t('claims.detail.update_button')" />
        </n-button-regular>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/theme/modal/modal";
import moment from "moment/moment";
import validation from "@/mixins/validation";
import { downloadFile } from "@/utils";

export default {
  name: "components.claims.modal-claim-details",
  props: ["onCloseModal", "show", "claim"],
  components: { Modal },
  mixin: [validation],
  data: () => ({
    data: {
      report_no: ""
    },
    form_states: {
      report_no: null
    },
    uploaded_files: [],
    files: []
  }),
  mounted() {},
  computed: {
    minDate() {
      return moment()
        .subtract(1, "years")
        .toDate();
    },
    maxDate() {
      return moment().toDate();
    },
    valid() {
      return (
        this.validateNotEmpty(this.data.report_no) && this.files.length > 0
      );
    },
    isTheftClaim() {
      return (
        this.claim.damage_cause === "BEROVING" ||
        this.claim.damage_cause === "VERMISSING" ||
        this.claim.damage_cause === "INBRAAK"
      );
    },
    isOnSiteRouting() {
      const ONSITE_ROUTINGS = ["Onsite", 4, "4"];
      return ONSITE_ROUTINGS.includes(this.claim.routing);
    },
    mustUpdateClaim() {
      return (
        this.isTheftClaim &&
        this.claim.status !== "Handled" &&
        this.claim.status !== "Closed" &&
        (!this.claim.official_report_no || this.claim.official_report_no === "")
      );
    }
  },
  methods: {
    getClaimType() {
      if (this.claim.damage_cause === "VALSCHADE") {
        return this.$t(`report.damage.type.type_drop`);
      } else if (this.claim.damage_cause === "VOCHTSCHADE") {
        return this.$t(`report.damage.type.type_water`);
      } else if (this.claim.damage_cause === "TECHNISCH DEFECT") {
        return this.$t(`report.damage.type.type_technical`);
      } else if (this.claim.damage_cause === "BEROVING") {
        return this.$t(`report.theft.type.type_robbery`);
      } else if (this.claim.damage_cause === "VERMISSING") {
        return this.$t(`report.theft.type.type_missing`);
      } else if (this.claim.damage_cause === "INBRAAK") {
        return this.$t(`report.theft.type.type_break_in`);
      }
      return "";
    },
    downloadShippingLabel() {
      const fileString = this.claim.shipping_label;

      const fileName = `${this.claim.navision_ext_id}`;

      downloadFile(fileString, fileName);
    },
    onChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (files.length) {
        this.uploaded_files = files;
      }
      this.uploaded_files.forEach(thisFile => {
        this.files.push({ name: thisFile.name, file: thisFile });
      });
      this.uploaded_files = [];
    },
    removeFile(i) {
      this.files.splice(i, 1);
    },
    updateTheftReport() {
      const payload = {
        report_no: this.data.report_no,
        report_date: moment().format("YYYY-MM-DD")
      };

      let formData = new FormData();

      if (this.files && this.files.length) {
        this.files.forEach((f, i) => {
          formData.append(`file-${i}`, f);
        });
      }

      formData.append("data", JSON.stringify(payload));

      this.$store
        .dispatch("claims/updateTheftReport", {
          id: this.claim.id,
          payload: formData
        })
        .then(() => {
          this.data = {
            report_no: ""
          };
          this.form_states = {
            report_no: null
          };

          this.onCloseModal();
        })
        .catch(() => {});
    }
  }
};
</script>
